export const siteMapData = ({ t }) => [
  {
    name: 'Fincantieri Infrastructure Opere Marittime S.p.A.',
    office1: `<p style="margin-bottom:0px">Sede Legale: Via Genova 1 - 34121 Trieste - Italy</p>`,
    office2: `Sede Operativa: Via Tiburtina 1072 – 00156 Rome - Italy`,
    register: '',
    legal: 
    `Registro delle Imprese Venezia Giulia<br />
    C.F. e n. iscr. 01336990328<br />
    P.IVA 01336990328<br />
    N. R.E.A.   TS - 205855<br />`,
    cap: `Capitale Sociale  € 100.000,00`,
    soc: `Società soggetta a direzione e coordinamento di Fincantieri Infrastructure S.p.A.<br />
    Società con socio unico `,
    contacts: `
    CONTATTI<br />
    <a href="mailto:segreteria-fiom@fincantieri-Infrastructure.it">segreteria-fiom@fincantieri-Infrastructure.it</a><br />
    <a href="mailto:fi-operemarittime@pec.fincantieri.it">fi-operemarittime@pec.fincantieri.it</a><br />
    <a href="https://www.fincantieri-infrastructure-operemarittime.com">www.fincantieri-infrastructure-operemarittime.com</a>
    `,
  },
  {

  },
];
