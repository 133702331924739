import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { NoMatch } from 'shared';
const Home = lazy(() => import('../pages/Home'));
const WhoWeAre = lazy(() => import('../pages/WhoWeAre'));
const OurHistory = lazy(() => import('../pages/OurHistory'));
const BusinessAreas = lazy(() => import('../pages/BusinessAreas'));
const OurStrength = lazy(() => import('../pages/OurStrength'));
const Brochure = lazy(() => import('../pages/Brochure'));
const Projects = lazy(() => import('../pages/Projects'));
const Case = lazy(() => import('../pages/Case'));
const Management = lazy(() => import('../pages/Management'));
const Ethics = lazy(() => import('../pages/Ethics'));
const AntiCorruption = lazy(() => import('../pages/AntiCorruption'));
const WhistleBlowing = lazy(() => import('../pages/WhistleBlowing'));
const CommitmentAndSustainability = lazy(() =>
  import('../pages/CommitmentAndSustainability'),
);
const Certifications = lazy(() => import('../pages/Certifications'));
const SA8000Report = lazy(() => import('../pages/SA8000Report'));
const Contacts = lazy(() => import('../pages/Contacts'));
const WorkWithUs = lazy(() => import('../pages/WorkWithUs'));
const Suppliers = lazy(() => import('../pages/Suppliers'));
const OurFleet = lazy(() => import('../pages/OurFleet'));

export const routes = [
  { exact: true, path: '/', component: <Home /> },
  { exact: true, path: '/index.html', component: <Home /> },

  {
    exact: true,
    path: '/profile',
    component: <Redirect to="/profile/who-we-are" />,
  },
  { exact: true, path: '/profile/who-we-are', component: <WhoWeAre /> },
  { exact: true, path: '/profile/our-history', component: <OurHistory /> },
  {
    exact: true,
    path: '/profile/business-areas',
    component: <BusinessAreas />,
  },
  { exact: true, path: '/profile/our-strength', component: <OurStrength /> },
  { exact: true, path: '/profile/brochure', component: <Brochure /> },

  { exact: true, path: '/projects', component: <Projects /> },
  { exact: true, path: '/projects/:id', component: <Case /> },

  { exact: true, path: '/our-fleet', component: <OurFleet /> },

  {
    exact: true,
    path: '/governance',
    component: <Redirect to="/governance/management" />,
  },
  { exact: true, path: '/governance/management', component: <Management /> },
  { exact: true, path: '/governance/ethics', component: <Ethics /> },
  {
    exact: true,
    path: '/governance/anti-corruption',
    component: <AntiCorruption />,
  },
  {
    exact: true,
    path: '/governance/whistle-blowing',
    component: <WhistleBlowing />,
  },

  {
    exact: true,
    path: '/sustainability',
    component: <Redirect to="/sustainability/commitment-and-sustainability" />,
  },
  {
    exact: true,
    path: '/sustainability/commitment-and-sustainability',
    component: <CommitmentAndSustainability />,
  },
  {
    exact: true,
    path: '/sustainability/certifications',
    component: <Certifications />,
  },
  {
    exact: true,
    path: '/sustainability/sa-8000-reports',
    component: <SA8000Report />,
  },

  { exact: true, path: '/contacts', component: <Contacts /> },

  { exact: true, path: '/work-with-us', component: <WorkWithUs /> },
  { exact: true, path: '/suppliers', component: <Suppliers /> },

  { exact: false, path: '*', component: <NoMatch /> },
];
